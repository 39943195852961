import { PriceDataSources } from 'components/UniSelect/constants';

import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  displayAs: yup.string().required('This field is required.'),
  groupId: yup.string().required('This field is required.'),
  product: yup.string().required('This field is required.'),
  incoTerms: yup.string().required('This field is required.'),
  format: yup.string().required('This field is required.'),
  unit: yup.string().required('This field is required.'),
  frequency: yup.string().required('This field is required.'),
  currency: yup.string().required('This field is required.'),
  scale: yup.string().required('This field is required.'),
  decimalPlaces: yup
    .number()
    .required('This field is required.')
    .min(0),
  region: yup.string().required('This field is required.'),
  priceDataSource: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceDataSymbols: yup.string().when('priceDataSource', {
    is: value => PriceDataSources.external.includes(value),
    then: schema => schema.required('This field is required.')
  })
});

export const DEFAULT_INITIAL_VALUES = {
  activeInWebsite: false,
  activeInNewsletters: false,
  title: '',
  shortTitle: '',
  displayAs: '',
  slug: '',
  category: '',
  product: '',
  groupId: '',
  region: null,
  region2: null,
  incoTerms: '',
  format: '',
  unit: '',
  frequency: '',
  currency: '',
  scale: '',
  decimalPlaces: 0,
  principalOwner: null,
  access: [],
  articleRegions: [],
  articlePrices: [],
  articleProducts: [],
  articleAuthors: [],
  metaTitle: '',
  metaKeywords: '',
  metaDescription: '',
  metaStandout: '',
  priceDataSource: PriceDataSources.Manual,
  priceDataSymbols: ''
};
