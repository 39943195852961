import React from 'react';

import { FormRow, UniSelect } from 'components';
import PriceSelectionFields from '../PriceSelectionFields';

export default function QuickAccessConversionControlFields({
  values,
  setFieldValue,
  productSlug,
  disabled = false
}) {
  return (
    <>
      <PriceSelectionFields
        values={values}
        setFieldValue={setFieldValue}
        productSlug={productSlug}
        disabled={disabled}
      />
      <FormRow
        widths="equal"
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: '1rem'
        }}>
        <UniSelect
          required
          name="format"
          label="Format:"
          source="priceSerieFormats"
          disabled={disabled}
        />

        <UniSelect
          required
          name="unit"
          label="Unit:"
          source="priceSerieUnits"
          disabled={disabled}
        />

        <UniSelect
          required
          name="frequency"
          label="Frequency:"
          source="priceSerieFrequencies"
          disabled={disabled}
        />
        <UniSelect
          required
          width={2}
          name="currency"
          label="Currency:"
          source="priceSerieCurrencies"
          disabled={disabled}
        />
      </FormRow>
    </>
  );
}
