import _ from 'lodash';
import React from 'react';
import { Header, Table } from 'semantic-ui-react';

import { Flex } from 'components';

import { usePriceSerieCombinationPreview } from './sdk';
import { formatDateForFrequency } from './utils';

import DataPointContainer from '../DataPointContainer';
import QuickAccessConversionControlFields from '../QuickAccessConversionControlFields';

function TableWrapper({ children }) {
  return (
    <div
      style={{
        maxHeight: '350px',
        overflowY: 'auto',
        display: 'block',
        width: '100%',
        border: '1px solid rgba(34, 36, 38, 0.15)',
        borderRadius: '5px'
      }}>
      {children}
    </div>
  );
}

export default function ResultsPreviewTable({
  priceSerie1,
  priceSerie2,
  values,
  setFieldValue,
  productSlug
}) {
  const {
    dataPoints,
    conversionStatistics,
    isLoading,
    errors
  } = usePriceSerieCombinationPreview({
    productSlug,
    values,
    shouldFetch: !_.isEmpty(priceSerie1) || !_.isEmpty(priceSerie2)
  });

  const resultDimension = !_.isEmpty(conversionStatistics)
    ? `${conversionStatistics.output_currency}/${conversionStatistics.output_unit}`
    : '';

  return (
    <Flex alignStart style={{ flexDirection: 'column', width: '100%' }}>
      <QuickAccessConversionControlFields
        values={values}
        setFieldValue={setFieldValue}
        productSlug={productSlug}
        disabled={isLoading}
      />
      <Header style={{ paddingLeft: '7px' }} as="h5">
        Results Preview Table
      </Header>
      <TableWrapper>
        <Table celled striped structured>
          <Table.Header style={{ position: 'sticky', top: 0 }}>
            <Table.Row>
              <Table.HeaderCell rowspan="2">Date</Table.HeaderCell>
              <Table.HeaderCell colspan="2">
                {isLoading ? 'Loading...' : priceSerie1?.display_as}
              </Table.HeaderCell>
              <Table.HeaderCell colspan="2">
                {isLoading ? 'Loading...' : priceSerie2?.display_as}
              </Table.HeaderCell>
              <Table.HeaderCell rowspan="2">
                Computed data point {resultDimension}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                Original {priceSerie1?.dimensions || ''}
              </Table.HeaderCell>
              <Table.HeaderCell>Converted {resultDimension}</Table.HeaderCell>
              <Table.HeaderCell>
                Original {priceSerie2?.dimensions || ''}
              </Table.HeaderCell>
              <Table.HeaderCell>Converted {resultDimension}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isLoading ? (
              <>
                {Array.from({ length: 6 }).map((_, idx) => (
                  <Table.Row key={idx}>
                    {Array.from({ length: 6 }).map((_, idx) => (
                      <Table.Cell key={idx}>Loading...</Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </>
            ) : (
              <>
                {!_.isEmpty(errors) &&
                  errors.map((error, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell
                        colspan="6"
                        textAlign="center"
                        style={{ padding: '60px 30px' }}>
                        <Header as="h3" color="red">
                          {error.message}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {_.isEmpty(errors) &&
                  dataPoints.map((combinedDataPoint, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>
                        {formatDateForFrequency(
                          combinedDataPoint.date,
                          values.frequency
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <DataPointContainer
                          values={_.get(
                            combinedDataPoint,
                            `inputs[${priceSerie1.id}].original`
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <DataPointContainer
                          values={_.get(
                            combinedDataPoint,
                            `inputs[${priceSerie1.id}].converted`
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <DataPointContainer
                          values={_.get(
                            combinedDataPoint,
                            `inputs[${priceSerie2.id}].original`
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <DataPointContainer
                          values={_.get(
                            combinedDataPoint,
                            `inputs[${priceSerie2.id}].converted`
                          )}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <DataPointContainer values={combinedDataPoint.output} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </>
            )}
          </Table.Body>
        </Table>
      </TableWrapper>
    </Flex>
  );
}
