import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';

import { Page } from 'components';

import { withLoggedUser, withPermissions } from 'hooks';

import { PERMISSIONS } from 'permissions';

import { PriceSeriesCombineForm } from './components';

function PriceSerieCombine({ user, crumbs }) {
  const { productSlug } = useParams();
  const { search } = useLocation();

  const history = useHistory();
  const queryParams = new URLSearchParams(search);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Combine Price Series</Header>
            <PriceSeriesCombineForm
              productSlug={productSlug}
              priceSerie1={parseInt(queryParams.get('priceSerie1'))}
              history={history}
            />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_PRICES])(PriceSerieCombine)
);
