import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const subscriptionDetail = async id => {
  const url = `${BASE_URL}/editorials/subscriptions/${id}/`;

  return await requestSdk(() => get(url));
};

export const subscriptionStatusesWithConfigurableBanners = async () => {
  const url = `${BASE_URL}/editorials/subscription-statuses/with-banners/`;

  return await requestSdk(() => get(url));
};
