import { PriceCombinationOperations } from './constants';

export const getOperationSign = operation => {
  switch (operation) {
    case PriceCombinationOperations.ADD:
      return '+';
    case PriceCombinationOperations.SUB:
      return '-';
    default:
      return '';
  }
};
