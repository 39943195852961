import _ from 'lodash';
import { Header, Label, Segment, Table } from 'semantic-ui-react';

import { PriceSerieDetail } from 'pages/PriceSerie';
import { getOperationSign } from 'entities/PriceSerie';

import { Link } from 'components';
import { pageUrl } from 'config/routes';

function PriceSerieLink({ productSlug, priceSerie }) {
  return (
    <Link
      url={pageUrl(PriceSerieDetail, {
        productSlug: productSlug,
        priceSeriesId: priceSerie.id
      })}>
      {priceSerie.display_as}
    </Link>
  );
}

function builPricedRow({ productSlug, priceSerie }) {
  return (
    <Table.Row>
      <Table.Cell>
        <PriceSerieLink productSlug={productSlug} priceSerie={priceSerie} />
      </Table.Cell>
      <Table.Cell>{priceSerie.product}</Table.Cell>
      <Table.Cell>{priceSerie.category}</Table.Cell>
      <Table.Cell>{priceSerie.format}</Table.Cell>
      <Table.Cell>{priceSerie.frequency}</Table.Cell>
    </Table.Row>
  );
}

export default function CombinedPriceComponents({
  productSlug,
  components,
  operation
}) {
  const leftPriceSerie = _.first(components);
  const rightPriceSerie = _.last(components);

  return (
    <Segment>
      <Header as="h2">Component prices</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Price Serie</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Format</Table.HeaderCell>
            <Table.HeaderCell>Frequency</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {builPricedRow({ productSlug, priceSerie: leftPriceSerie })}
          <Table.Row>
            <Table.Cell colSpan={5}>
              <Label ribbon color="blue" size="big">
                Operation: {getOperationSign(operation)}{' '}
                {_.capitalize(operation.toLowerCase())}
              </Label>
            </Table.Cell>
          </Table.Row>

          {builPricedRow({ productSlug, priceSerie: rightPriceSerie })}
        </Table.Body>
      </Table>
    </Segment>
  );
}
