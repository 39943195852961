export default function DataPointContainer({ values }) {
  if (!values || !values.length) {
    return null;
  }

  if (values.length === 2) {
    return (
      <span>
        {values[0]} - {values[1]}
      </span>
    );
  }

  return <span>{values[0]}</span>;
}
