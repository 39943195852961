import _ from 'lodash';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSerieList } from 'pages';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { ErrorsAndCreateButtonInRow, UnsavedFormPrompt } from 'components';

import { priceSeriesCreate } from './sdk';
import { DEFAULT_INITIAL_VALUES, validationSchema } from './utils';
import FormFields from './FormFields';

function PriceSeriesCreateForm() {
  const { productSlug } = useParams();
  const history = useHistory();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...prepareFormData(values),
      regions: _.without([values.region, values.region2], null)
    };

    setSubmitting(true);

    const { errors, success } = await priceSeriesCreate(productSlug, data);

    if (success) {
      notifySuccess('Price series created.', {
        onOpen: history.push(pageUrl(PriceSerieList, { productSlug }))
      });
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={DEFAULT_INITIAL_VALUES}
      validationSchema={validationSchema}>
      {({
        isSubmitting,
        handleSubmit,
        setFieldValue,
        values,
        dirty,
        touched,
        errors
      }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
          <FormFields
            values={values}
            productSlug={productSlug}
            setFieldValue={setFieldValue}
          />
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}

export default PriceSeriesCreateForm;
