import { DateField, UniSelect, AdminSelect } from 'components';
import { transformValuesForMultiselect } from 'utils/filters';
import { POWER_MATERIAL_SLUG } from 'domainConstants';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';

export const FREQUENCIES = [DAILY, WEEKLY, MONTHLY, QUARTERLY];

export const FILTERS = productSlug => [
  [
    {
      name: productSlug === POWER_MATERIAL_SLUG ? 'main_group' : 'category',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Category',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieCategoriesForFilters',
        isMulti: true
      })
    },
    {
      name: 'product',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Product',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieProducts',
        isMulti: true
      })
    },
    {
      name: 'principal_owner',
      emptyValue: null,
      fieldComponent: AdminSelect,
      transform: value => parseInt(value, 10),
      label: 'Principal owner'
    }
  ],
  [
    {
      name: 'region',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Region',
      props: () => ({
        source: 'priceSerieRegions',
        isMulti: true
      })
    },
    {
      name: 'frequency',
      emptyValue: '',
      fieldComponent: UniSelect,
      label: 'Frequency',
      props: () => ({
        source: 'priceSerieFrequencies'
      })
    },
    {
      name: 'date',
      emptyValue: '',
      component: DateField,
      label: 'Date'
    }
  ],
  [
    {
      name: 'combined_price',
      emptyValue: null,
      fieldComponent: UniSelect,
      transform: value => parseInt(value, 10),
      label: 'Combined Price',
      props: () => ({
        source: 'combinedPriceSeries'
      })
    }
  ]
];
