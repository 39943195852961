import React, { useCallback } from 'react';
import { Table } from 'semantic-ui-react';

import { prepareFormData } from 'utils/forms';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { subscriptionOrderBanner } from './sdk';
import BannerForm from './BannerForm';
import SortableTableRow from './SortableTableRow';
import styles from './styles.module.css';

const BannersTable = ({
  items,
  subscriptionStatuses,
  subscription,
  fetchSubscription
}) => {
  const onSortEnd = useCallback(
    async ({ item: banner, oldIndex, newIndex }) => {
      const data = {
        banner: banner.id,
        oldIndex,
        newIndex
      };

      const { success, errors } = await subscriptionOrderBanner(
        subscription.id,
        prepareFormData(data)
      );

      if (success) {
        fetchSubscription();
        notifySuccess('Banners reordered.');
      } else {
        notifyErrors(errors);
      }
    },
    [subscription.id, fetchSubscription]
  );

  return (
    <div className={styles.TableScroll}>
      <Table celled collapsing>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell />
            <Table.HeaderCell width={2}>Banner Thumbnail</Table.HeaderCell>
            <Table.HeaderCell width={2}>Image</Table.HeaderCell>
            <Table.HeaderCell width={2}>URL</Table.HeaderCell>
            {subscriptionStatuses.map(status => (
              <Table.HeaderCell key={status.id}>
                {status.title}
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <SortableTableRow
          items={items}
          onSortEnd={onSortEnd}
          itemComponent={({ item }) => (
            <BannerForm
              banner={item}
              statuses={subscriptionStatuses}
              subscription={subscription}
              fetchSubscription={fetchSubscription}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default BannersTable;
