import { useEffect, useMemo, useState } from 'react';

import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

import { notifyErrors } from 'utils/notifications';

export const priceSerieDetail = async id => {
  const url = `${BASE_URL}/prices/series/v2/${id}/detail/`;

  return await requestSdk(() => get(url));
};

export const usePriceSerieDetail = ({ id, shoudFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceSerie, setPriceSerie] = useState();

  useEffect(() => {
    const fetchPriceSerie = async () => {
      setIsLoading(true);
      const { data, errors, success } = await priceSerieDetail(id);

      if (success) {
        setPriceSerie(data);
      } else {
        notifyErrors(errors);
      }

      setIsLoading(false);
    };

    if (shoudFetch) {
      fetchPriceSerie();
    }
  }, [id, shoudFetch]);

  return useMemo(() => ({ isLoading, priceSerie }), [isLoading, priceSerie]);
};
