import React from 'react';

import _ from 'lodash';

import { Segment, Header } from 'semantic-ui-react';

import { HYDROGEN } from 'domainConstants';
import { Page, LoginRequired, Flex, OpenModalButton } from 'components';

import {
  SubscriptionUpdateForm,
  BannersTable,
  CreateBannerModal,
  SubscriptionFeaturesOrderModal,
  SubscriptionFeatures
} from './components';

import {
  subscriptionDetail,
  subscriptionStatusesWithConfigurableBanners
} from './sdk';

class SubscriptionDetail extends React.Component {
  state = {
    subscriptionData: null,
    statuses: []
  };

  componentDidMount() {
    this.fetchSubscription();
    this.fetchSubscriptionStatuses();
  }

  fetchSubscription = async () => {
    const { subscriptionId } = this.props.match.params;

    const { data, errors, success } = await subscriptionDetail(subscriptionId);

    if (success) {
      this.setState({ subscriptionData: data });
    } else {
      this.setState({ errors });
    }
  };

  fetchSubscriptionStatuses = async () => {
    const {
      data,
      success
    } = await subscriptionStatusesWithConfigurableBanners();

    if (success) {
      this.setState({ statuses: data });
    }
  };

  render() {
    const { subscriptionData, statuses } = this.state;

    const { features } = subscriptionData || [];

    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            {!_.isNil(subscriptionData) && !_.isNil(statuses) && (
              <>
                <Segment>
                  <Header>Edit Subscription</Header>
                  <SubscriptionUpdateForm
                    data={subscriptionData}
                    subscription={subscriptionData}
                    fetchSubscription={this.fetchSubscription}
                  />
                </Segment>
                <Segment>
                  <Flex spaceBetween>
                    <Header>Subscription Features</Header>
                    <OpenModalButton
                      size="small"
                      color="yellow"
                      disabled={_.isEmpty(features)}
                      modal={{
                        header: 'Order Subscription Features',
                        render: props => (
                          <SubscriptionFeaturesOrderModal
                            features={features}
                            refetch={this.fetchSubscription}
                            {...props}
                          />
                        )
                      }}>
                      Order Subscription Features
                    </OpenModalButton>
                  </Flex>
                  {subscriptionData && (
                    <SubscriptionFeatures
                      subscription={subscriptionData}
                      fetchSubscription={this.fetchSubscription}
                    />
                  )}
                </Segment>
                {subscriptionData.title !== HYDROGEN && (
                  <Segment>
                    <Flex spaceBetween>
                      <Header>Subscription Banners</Header>
                      {subscriptionData.banners.length < 10 && (
                        <OpenModalButton
                          color="green"
                          modal={{
                            header: 'Create Banner',
                            render: props => (
                              <CreateBannerModal
                                statuses={statuses}
                                subscription={subscriptionData}
                                fetchSubscription={this.fetchSubscription}
                                {...props}
                              />
                            )
                          }}>
                          Create Banner
                        </OpenModalButton>
                      )}
                    </Flex>
                    <BannersTable
                      subscriptionStatuses={statuses}
                      items={subscriptionData.banners}
                      subscription={subscriptionData}
                      fetchSubscription={this.fetchSubscription}
                    />
                  </Segment>
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(SubscriptionDetail);
