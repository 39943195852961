import _ from 'lodash';
import { useEffect } from 'react';
import { withFormik } from 'formik';
import { Dimmer, DimmerDimmable, Form, Segment } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { pageUrl } from 'config/routes';

import { PriceCombinationOperations } from 'entities/PriceSerie';

import { usePriceSerieDetail } from 'pages/PriceSerie/Detail/sdk';
import CreatePriceFormFields from 'pages/PriceSerie/Create/components/PriceSeriesCreateForm/FormFields';
import {
  validationSchema,
  DEFAULT_INITIAL_VALUES
} from 'pages/PriceSerie/Create/components/PriceSeriesCreateForm/utils';
import { PriceSerieList } from 'pages/PriceSerie';

import {
  ErrorsAndCreateButtonInRow as FormActionsRow,
  UnsavedFormPrompt,
  Header
} from 'components';
import { PriceDataSources } from 'components/UniSelect/constants';

import { priceSeriesCombine } from './sdk';

import PreviewResultsModalButton from '../PreviewResultsModalButton';
import PriceSelectionFields from '../PriceSelectionFields';

function PriceSeriesCombineForm({
  isSubmitting,
  handleSubmit,
  values,
  dirty,
  touched,
  errors,
  setFieldValue,
  productSlug
}) {
  const { priceSerie: priceSerie1 } = usePriceSerieDetail({
    id: values.price_serie_1,
    shoudFetch: !_.isNil(values.price_serie_1)
  });

  const { priceSerie: priceSerie2 } = usePriceSerieDetail({
    id: values.price_serie_2,
    shoudFetch: !_.isNil(values.price_serie_2)
  });

  const areDetailFieldsDisabled =
    !values.price_serie_1 || !values.price_serie_2;

  useEffect(() => {
    if (priceSerie1) {
      setFieldValue('format', priceSerie1.format);
      setFieldValue('unit', priceSerie1.unit);
      setFieldValue('frequency', priceSerie1.frequency);
      setFieldValue('currency', priceSerie1.currency);
      setFieldValue('scale', priceSerie1.scale);
      setFieldValue('decimalPlaces', priceSerie1.decimal_places);
    }
  }, [setFieldValue, priceSerie1]);

  return (
    <Form>
      <UnsavedFormPrompt when={dirty && !isSubmitting} />
      <FormActionsRow
        errors={errors}
        touched={touched}
        onClick={handleSubmit}
        disabled={isSubmitting}
        additonalActions={
          <PreviewResultsModalButton
            priceSerie1={priceSerie1}
            priceSerie2={values.price_serie_2 && priceSerie2}
            values={values}
            setFieldValue={setFieldValue}
            productSlug={productSlug}
          />
        }
      />

      <PriceSelectionFields
        values={values}
        setFieldValue={setFieldValue}
        productSlug={productSlug}
      />

      <Header as="h3">Combined price details</Header>
      <DimmerDimmable id="asd" as={Segment} dimmed={areDetailFieldsDisabled}>
        <CreatePriceFormFields
          values={values}
          setFieldValue={setFieldValue}
          productSlug={productSlug}
          disabled={areDetailFieldsDisabled}
          fieldsConfig={{ priceDataSource: { disabled: true } }}
        />
        <Dimmer
          active={areDetailFieldsDisabled}
          verticalAlign="top"
          style={{ backgroundColor: 'rgba(248, 248, 249, 0.7)', zIndex: 0 }}>
          <Header as="h2">Select price series to combine</Header>
        </Dimmer>
      </DimmerDimmable>

      <FormActionsRow
        errors={errors}
        touched={touched}
        onClick={handleSubmit}
        disabled={isSubmitting}
        additonalActions={
          <PreviewResultsModalButton
            priceSerie1={priceSerie1}
            priceSerie2={values.price_serie_2 && priceSerie2}
            values={values}
            setFieldValue={setFieldValue}
            productSlug={productSlug}
          />
        }
      />
    </Form>
  );
}

export default withFormik({
  mapPropsToValues: props => ({
    price_serie_1: props?.priceSerie1 || null,
    price_serie_2: null,
    operation: PriceCombinationOperations.ADD,
    ...DEFAULT_INITIAL_VALUES,
    priceDataSource: PriceDataSources.Combined
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { productSlug, history } = props;

    const data = {
      ...prepareFormData(values),
      regions: _.without([values.region, values.region2], null)
    };

    setSubmitting(true);

    const { errors, success } = await priceSeriesCombine(productSlug, data);

    if (success) {
      notifySuccess('Price series created.', {
        onOpen: history.push(pageUrl(PriceSerieList, { productSlug }))
      });
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  },
  validationSchema: validationSchema
})(PriceSeriesCombineForm);
