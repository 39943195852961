import { Button, ButtonGroup, ButtonOr } from 'semantic-ui-react';
import React from 'react';

const inactiveStyle = {
  color: '#2185d0',
  background: 'white',
  border: '1px solid #2185d0'
};

const activeStyle = {
  color: 'white',
  background: '#2185d0'
};

const ChoiceToggle = ({ leftOption, rightOption, style = {} }) => {
  return (
    <ButtonGroup style={{ width: '100%', ...style }}>
      <Button
        inverted
        style={leftOption.predicate ? activeStyle : inactiveStyle}
        active={leftOption.predicate}
        onClick={leftOption.onClick}>
        {leftOption.text}
      </Button>
      <ButtonOr style={{ alignSelf: 'center' }} />
      <Button
        inverted
        style={rightOption.predicate ? activeStyle : inactiveStyle}
        active={rightOption.predicate}
        onClick={rightOption.onClick}>
        {rightOption.text}
      </Button>
    </ButtonGroup>
  );
};

export default ChoiceToggle;
