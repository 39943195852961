import { OpenModalButton } from 'components';

import ResultsPreviewTable from '../ResultsPreviewTable';

export default function PreviewResultsModalButton({
  priceSerie1,
  priceSerie2,
  values,
  setFieldValue,
  productSlug
}) {
  return (
    <OpenModalButton
      disabled={!priceSerie1 || !priceSerie2}
      modal={{
        header: 'Data points combination preview',
        render: props => (
          <ResultsPreviewTable
            priceSerie1={priceSerie1}
            priceSerie2={priceSerie2}
            values={values}
            setFieldValue={setFieldValue}
            productSlug={productSlug}
          />
        )
      }}>
      Preview Combination
    </OpenModalButton>
  );
}
