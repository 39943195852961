import _ from 'lodash';
import React from 'react';
import { Formik } from 'formik';

import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import { sortableHandle } from 'react-sortable-hoc';

import { Field, FileField, InputField, Image } from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { subscriptionBannerUpdate } from './sdk';
import { bannerUpdateSchema } from './utils';

const DragHandle = sortableHandle(() => (
  <Table.Cell style={{ cursor: 'grab' }}>
    <Icon name="bars" />
  </Table.Cell>
));

class BannerForm extends React.Component {
  state = {
    checkedStatuses: []
  };

  componentDidMount() {
    const { banner } = this.props;

    this.setState({
      checkedStatuses: banner.statuses.map(status => status.id)
    });
  }

  componentDidUpdate(prevProps) {
    const { banner } = this.props;
    const prevBanner = prevProps.banner;

    if (!_.isEqual(banner.statuses, prevBanner.statuses)) {
      this.setState({
        checkedStatuses: banner.statuses.map(status => status.id)
      });
    }
  }

  toggleStatus = status => {
    this.setState(prevState => ({
      checkedStatuses: _.xor(prevState.checkedStatuses, [status])
    }));
  };

  handleSubmit = async (values, actions) => {
    const { banner, subscription, fetchSubscription } = this.props;
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...values,
      statuses: this.state.checkedStatuses,
      banner: banner.id
    };

    setSubmitting(true);

    const { success, errors } = await subscriptionBannerUpdate(
      subscription.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Banner is updated.`);
      fetchSubscription();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const {
      banner,
      statuses,
      banner: { image, url }
    } = this.props;

    const { checkedStatuses } = this.state;

    const initialValues = { image, url };

    return (
      <Formik
        enableReinitialize
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={bannerUpdateSchema}>
        {({ handleSubmit, isSubmitting }) => (
          <>
            <DragHandle />

            <Table.Cell>
              <Image
                url={banner.image_url}
                width="180"
                height="73"
                alt="Banner"
                link={true}
              />
            </Table.Cell>

            <Table.Cell>
              <Field name="image" small component={FileField} />
            </Table.Cell>

            <Table.Cell>
              <Field required name="url" component={InputField} />
            </Table.Cell>

            {statuses.map(status => (
              <Table.Cell key={`status-${status.id}`}>
                <Checkbox
                  onChange={() => this.toggleStatus(status.id)}
                  checked={_.indexOf(checkedStatuses, status.id) > -1}
                />
              </Table.Cell>
            ))}

            <Table.Cell>
              <Button
                style={{ padding: '0.4em' }}
                onClick={handleSubmit}
                type="submit"
                color="green"
                disabled={isSubmitting}
                icon>
                <Icon name="check" />
              </Button>
            </Table.Cell>
          </>
        )}
      </Formik>
    );
  }
}

export default BannerForm;
