import { Table } from 'semantic-ui-react';
import { YesNo } from 'components';
import { formatDate } from 'utils';

import styles from '../../styles.module.css';

const SteelTrialPreferencesTable = ({ preference }) => {
  const otherServices = [];

  // We want the `Cross sold` service to be the first in the Other Services list
  if (preference.cross_sold) {
    otherServices.push('Kallanish Power Materials');
  }

  otherServices.push(...preference.other_steel_services);

  return (
    <Table
      definition
      collapsing
      className={styles.tableStyle}
      style={{ marginTop: '0' }}>
      <Table.Row>
        <Table.Cell width={5}>Subscription Type</Table.Cell>
        <Table.Cell>
          {preference.subscription_type}{' '}
          {preference.steel_frequency === 'Weekly' && 'Weekly Standard'}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Frequency</Table.Cell>
        <Table.Cell>{preference.steel_frequency || 'N/A'}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Group articles by</Table.Cell>
        <Table.Cell>{preference.group_articles_by || 'N/A'}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Other Services</Table.Cell>
        <Table.Cell>
          {otherServices.length === 0 && 'N/A'}
          {otherServices.length !== 0 && (
            <ul style={{ paddingLeft: '10px' }}>
              {otherServices.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        {preference.steel_frequency === 'Weekly' && (
          <>
            <Table.Cell>Upgrade to Kallanish Steel Weekly Premium</Table.Cell>
            <Table.Cell>
              <YesNo
                value={preference.upgrade_to_weekly_premium}
                style={{ justifyContent: 'start' }}
              />
            </Table.Cell>
          </>
        )}
        {preference.steel_frequency === 'Daily' && (
          <>
            <Table.Cell>Upgrade to Kallanish Steel LIVE</Table.Cell>
            <Table.Cell>
              <YesNo
                value={preference.upgrade_to_live}
                style={{ justifyContent: 'start' }}
              />
            </Table.Cell>
          </>
        )}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Start Date</Table.Cell>
        <Table.Cell>{formatDate(preference.start_date)}</Table.Cell>
      </Table.Row>
    </Table>
  );
};

export default SteelTrialPreferencesTable;
