import { ChoiceToggle, FormRow, UniSelect } from 'components';
import { PriceCombinationOperations } from 'entities/PriceSerie';

export default function PriceSelectionFields({
  values,
  setFieldValue,
  productSlug,
  disabled = false
}) {
  return (
    <FormRow
      alignEnd
      widths="equal"
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '1rem'
      }}>
      <UniSelect
        required
        isClearable={false}
        name="price_serie_1"
        source="priceSeriesAll"
        label="First Price Serie"
        excludeOptionsIds={[values.price_serie_2]}
        params={{ product_type: productSlug }}
        disabled={disabled}
      />
      <ChoiceToggle
        style={{ minHeight: '48px' }}
        leftOption={{
          predicate: values.operation === PriceCombinationOperations.ADD,
          onClick: () =>
            setFieldValue('operation', PriceCombinationOperations.ADD),
          text: '+ Add'
        }}
        rightOption={{
          predicate: values.operation === PriceCombinationOperations.SUB,
          onClick: () =>
            setFieldValue('operation', PriceCombinationOperations.SUB),
          text: '- Subtract'
        }}
      />
      <UniSelect
        required
        isClearable={false}
        name="price_serie_2"
        source="priceSeriesAll"
        label="Second Price Serie"
        excludeOptionsIds={[values.price_serie_1]}
        params={{
          product_type: productSlug
        }}
        disabled={disabled}
      />
    </FormRow>
  );
}
