import React from 'react';

import { Button as SemanticButton } from 'semantic-ui-react';

import { FormRow, ErrorsAndButtonsInRow } from 'components';

const CreateButton = ({ capital, style, children, ...props }) => {
  return (
    <SemanticButton
      style={{
        textTransform: capital && 'uppercase',
        ...style
      }}
      {...props}>
      {children || 'Submit'}
    </SemanticButton>
  );
};

CreateButton.defaultProps = {
  type: 'submit',
  size: 'large',
  color: 'green'
};

const CreateButtonInRow = props => (
  <FormRow flexEnd>
    <CreateButton {...props} />
  </FormRow>
);

const ErrorsAndCreateButtonInRow = ({
  errors,
  touched,
  additonalActions = undefined,
  ...props
}) => (
  <ErrorsAndButtonsInRow touched={touched} errors={errors}>
    <div>
      {additonalActions}
      <CreateButton {...props} />
    </div>
  </ErrorsAndButtonsInRow>
);

export { CreateButton, CreateButtonInRow, ErrorsAndCreateButtonInRow };
