import { Table } from 'semantic-ui-react';
import { formatDate } from 'utils';

import styles from '../../styles.module.css';

const PMTrialPreferencesTable = ({ preference }) => {
  const hasEmptyService = preference.other_pm_services[0] === '';
  const otherServices = [];

  if (preference.cross_sold) {
    otherServices.push(
      `Kallanish Steel${
        preference.steel_frequency === 'Weekly' ? ' Weekly Standard' : ''
      }`
    );
  }

  if (!hasEmptyService) {
    otherServices.push(...preference.other_pm_services);
  }

  return (
    <Table
      definition
      collapsing
      className={styles.tableStyle}
      style={{ marginTop: '0' }}>
      <Table.Row>
        <Table.Cell width={5}>Subscription Type</Table.Cell>
        <Table.Cell>{preference.subscription_type}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Other Services</Table.Cell>
        <Table.Cell>
          {otherServices.length === 0 && 'N/A'}
          {otherServices.length !== 0 && (
            <ul style={{ paddingLeft: '10px' }}>
              {otherServices.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Start Date</Table.Cell>
        <Table.Cell>{formatDate(preference.start_date)}</Table.Cell>
      </Table.Row>
    </Table>
  );
};

export default PMTrialPreferencesTable;
