import _ from 'lodash';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { arrayMoveImmutable } from 'array-move';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import styles from './styles.module.css';

const SortableItem = sortableElement(({ item, itemComponent }) => {
  const ItemComponent = itemComponent;

  return (
    <Table.Row textAlign="center" className={styles.SortableItem}>
      <ItemComponent item={item} />
    </Table.Row>
  );
});

const SortableContainer = sortableContainer(({ children }) => (
  <Table.Body>{children}</Table.Body>
));

class SortableTableRow extends React.Component {
  state = {
    items: []
  };

  componentDidMount() {
    const { items } = this.props;
    this.setState({ items });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.items, this.props.items)) {
      this.setState({ items: this.props.items });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const item = this.state.items[oldIndex];

    if (oldIndex === newIndex) {
      return;
    }

    this.setState(
      ({ items }) => ({
        items: arrayMoveImmutable(items, oldIndex, newIndex)
      }),
      () => this.props.onSortEnd({ item, oldIndex, newIndex })
    );
  };

  render() {
    const { items } = this.state;
    const { itemComponent } = this.props;

    return (
      <SortableContainer useDragHandle onSortEnd={this.onSortEnd}>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item.title}-${index}`}
            index={index}
            item={item}
            itemComponent={itemComponent}
          />
        ))}
      </SortableContainer>
    );
  }
}

export default SortableTableRow;
