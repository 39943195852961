import React from 'react';
import { Divider } from 'semantic-ui-react';

import { populateSlug } from 'utils';

import {
  Field,
  InputField,
  FormRow,
  CheckboxField,
  AdminSelect,
  RegionSelect,
  UniSelect
} from 'components';

import { HYDROGEN_SLUG } from 'domainConstants';
import { PriceDataSources } from 'components/UniSelect/constants';

export default function FormFields({
  values,
  setFieldValue,
  productSlug,
  disabled = false,
  fieldsConfig = {}
}) {
  const showExternalSourceSymbol =
    values['priceDataSource'] &&
    PriceDataSources.external.includes(values['priceDataSource']);

  const isFieldDisabled = fieldName =>
    disabled ? true : fieldsConfig[fieldName]?.disabled || false;

  return (
    <>
      <FormRow>
        <Field
          width={2}
          name="activeInWebsite"
          label="Show on home page"
          component={CheckboxField}
          disabled={isFieldDisabled('activeInWebsite')}
        />

        <Field
          width={2}
          name="activeInNewsletters"
          label="Show in right column"
          component={CheckboxField}
          disabled={isFieldDisabled('activeInNewsletters')}
          style={{ whiteSpace: 'nowrap' }}
        />
      </FormRow>
      <FormRow>
        <Field
          width={6}
          name="title"
          label="Title:"
          component={InputField}
          onChange={(e, data) => {
            populateSlug(data, setFieldValue);
          }}
          disabled={isFieldDisabled('title')}
        />
        <Field
          width={4}
          name="shortTitle"
          label="Short title:"
          component={InputField}
          disabled={isFieldDisabled('shortTitle')}
        />
      </FormRow>
      <FormRow>
        <Field width={6} name="slug" label="Slug:" component={InputField} />
        <Field
          width={4}
          name="displayAs"
          label="Display as:"
          component={InputField}
          disabled={isFieldDisabled('displayAs')}
        />
      </FormRow>
      <FormRow>
        <UniSelect
          required
          width={5}
          name="groupId"
          label="Category:"
          source="priceSerieCategories"
          productSlug={productSlug}
          disabled={isFieldDisabled('groupId')}
        />

        <UniSelect
          required
          width={5}
          name="product"
          label="Product:"
          source="priceSerieProducts"
          productSlug={productSlug}
          disabled={isFieldDisabled('product')}
        />
      </FormRow>
      <FormRow>
        <UniSelect
          required
          width={5}
          name="region"
          label="Region:"
          source="priceSerieRegions"
          disabled={isFieldDisabled('region')}
        />

        <UniSelect
          width={5}
          name="region2"
          label="Region 2:"
          source="priceSerieRegions"
          disabled={isFieldDisabled('region2')}
        />
      </FormRow>
      <FormRow widths="equal">
        <UniSelect
          required
          name="incoTerms"
          label="INCO terms:"
          source="priceSerieIncoTerms"
          disabled={isFieldDisabled('incoTerms')}
        />

        <UniSelect
          required
          name="format"
          label="Format:"
          source="priceSerieFormats"
          disabled={isFieldDisabled('format')}
        />

        <UniSelect
          required
          name="unit"
          label="Unit:"
          source="priceSerieUnits"
          disabled={isFieldDisabled('unit')}
        />

        <UniSelect
          required
          name="frequency"
          label="Frequency:"
          source="priceSerieFrequencies"
          disabled={isFieldDisabled('frequency')}
        />
      </FormRow>
      <FormRow>
        <UniSelect
          required
          width={2}
          name="currency"
          label="Currency:"
          source="priceSerieCurrencies"
          disabled={isFieldDisabled('currency')}
        />

        <UniSelect
          required
          width={2}
          name="scale"
          label="Scale:"
          source="priceSerieScales"
          disabled={isFieldDisabled('scale')}
        />
        <Field
          required
          width={3}
          name="decimalPlaces"
          label="Decimal places:"
          component={InputField}
          type="number"
          disabled={isFieldDisabled('decimalPlaces')}
        />
      </FormRow>
      <FormRow>
        <UniSelect
          required
          width={4}
          name="priceDataSource"
          label="Price Data Source"
          source="priceSerieDataSources"
          disabled={isFieldDisabled('priceDataSource')}
          disabledChoices={[PriceDataSources.Combined]}
        />
        {showExternalSourceSymbol && (
          <Field
            required
            width={4}
            name="externalSourceSymbol"
            label="Price Data External Symbols:"
            component={InputField}
            disabled={isFieldDisabled('externalSourceSymbol')}
          />
        )}
      </FormRow>
      <FormRow>
        <AdminSelect
          width={4}
          name="principalOwner"
          label="Principal owner"
          disabled={isFieldDisabled('principalOwner')}
        />
      </FormRow>
      <AdminSelect
        enableSelectAll
        width={16}
        name="access"
        isMulti={true}
        label="Access:"
        disabled={isFieldDisabled('access')}
      />
      <RegionSelect
        enableSelectAll
        isMulti={true}
        name="articleRegions"
        label="Region for articles:"
        source="regions"
        disabled={isFieldDisabled('articleRegions')}
      />
      <UniSelect
        enableSelectAll
        isMulti={true}
        disabled={
          productSlug === HYDROGEN_SLUG || isFieldDisabled('articleCategories')
        }
        name="articleCategories"
        label="Prices for articles:"
        source="priceSerieCategories"
        productSlug={isFieldDisabled('articleCategories')}
      />
      <UniSelect
        enableSelectAll
        isMulti={true}
        disabled={
          productSlug === HYDROGEN_SLUG || isFieldDisabled('articleProducts')
        }
        name="articleProducts"
        label="Products for articles:"
        source="priceSerieProducts"
        productSlug={isFieldDisabled('articleProducts')}
      />
      <AdminSelect
        enableSelectAll
        isMulti={true}
        name="articleAuthors"
        label="Authors for articles:"
        disabled={isFieldDisabled('articleAuthors')}
      />
      <Divider />
      <FormRow widths="equal">
        <Field
          name="metaTitle"
          label="Meta Title"
          component={InputField}
          disabled={isFieldDisabled('metaTitle')}
        />

        <Field
          name="metaKeywords"
          label="Meta Keywords"
          component={InputField}
          disabled={isFieldDisabled('metaKeywords')}
        />

        <Field
          name="metaDescription"
          label="Meta Description"
          component={InputField}
          disabled={isFieldDisabled('metaDescription')}
        />

        <Field
          name="metaStandout"
          label="Meta Standout"
          component={InputField}
          disabled={isFieldDisabled('metaStandout')}
        />
      </FormRow>
    </>
  );
}
