import React from 'react';

import { Table, Segment, Loader } from 'semantic-ui-react';

import { TableFooter, SortableHeader } from 'components';

import RegistrationAttemptRow from './RegistrationAttemptRow';

const RegistrationAttemptTable = ({
  registerAttempts,
  sort,
  orderBy,
  pagination,
  isFetching
}) => (
  <>
    {!isFetching && (
      <Table celled selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="15">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
              Attempt time
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
              Last update
            </SortableHeader>
            <Table.HeaderCell>Registration type</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Names</Table.HeaderCell>
            <Table.HeaderCell>Job title</Table.HeaderCell>
            <Table.HeaderCell>Company name</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Finished</Table.HeaderCell>
            <Table.HeaderCell>Finished by</Table.HeaderCell>
            <Table.HeaderCell>From Free Trial</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {registerAttempts.map(item => (
            <RegistrationAttemptRow item={item} />
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    )}
    {isFetching && (
      <Segment>
        <Loader />
      </Segment>
    )}
  </>
);

export default RegistrationAttemptTable;
