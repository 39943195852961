import moment from 'moment';
import { DAILY, WEEKLY, MONTHLY } from 'pages/PriceSerie/Update/utils';

const DateTransformLookup = new Map([
  [DAILY, date => moment(date).format('ll')],
  [
    WEEKLY,
    // values from BE are always the last day of the week
    date =>
      `${moment(date).format('ll')} - ${moment(date)
        .add(6, 'days')
        .format('ll')}`
  ],
  [MONTHLY, date => moment(date).format('MMM YYYY')]
]);

export function formatDateForFrequency(date, frequency) {
  if (!date || !frequency) {
    return undefined;
  }

  const transformFunc = DateTransformLookup.get(frequency.toLowerCase());

  if (!transformFunc) {
    return undefined;
  }
  return transformFunc(date);
}
